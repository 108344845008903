import React from 'react'
import { graphql } from 'gatsby'
import DatoCMSModel from '../models/DatoCMSModel'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'

import Hero from '@v/algorithm-updates/hero'
import History from '@v/algorithm-updates/history'

const Page = ({ data, pageContext }) => {
  const {
    page,
    updates: { edges: updateEdges }
  } = data
  const { title } = page
  const model = new DatoCMSModel(page)

  const updates = updateEdges.map(edge => edge.node)

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps() }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      <Hero
        titleSmall={page.heroTitleSmall}
        titleBig={page.heroTitleBig}
        description={page.description}
        image={page.heroImage}
        imageAsset={page.heroImageAsset}
      />

      <History
        title={page.updatesTitle}
        updates={updates}
        locale={pageContext.locale}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query AlgorithUpdatesPageQuery($locale: String) {
    page: datoCmsAlgorithmUpdatesPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      heroTitleSmall
      heroTitleBig
      description
      heroImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      updatesTitle
    }

    updates: allDatoCmsAlgorithmUpdate(
      filter: { locale: { eq: $locale }, title: { regex: "/.+/" } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          date
          description
          confirmed
          category {
            title
            slug
          }
        }
      }
    }
  }
`
