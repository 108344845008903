import React, { useMemo, useState } from 'react'
import cn from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import min from 'lodash/min'
import max from 'lodash/max'
import times from 'lodash/times'

import PageHeader from '@c/page-header'
import AlgorithmHistory from '@c/algorithm-history'

import useResourcesFilter from '@h/use-resources-filter'
import useForceBlank from '@/hooks/use-force-blank'
import getContentByLocale from '@u/get-content-by-locale'

const ALL_YEARS_FILTER = {
  label: null,
  max: 3000,
  min: 1000
}

const BEFORE_2000_FILTER = {
  label: '< 2000',
  max: 2000,
  min: 1000
}

const query = graphql`
  query {
    translations: allDatoCmsTranslation {
      nodes {
        locale
        optionAll
      }
    }
  }
`

function makeYearFilters(updates) {
  const years = uniq(
    map(updates, 'date').map(date => new Date(date).getFullYear())
  )
  const maxYear = max(years)
  const minYear = Math.max(min(years), 2000)
  const addBefore2000 = min(years) < 2000
  const filterCount = maxYear - minYear + 1
  const filters = times(filterCount, i => {
    return {
      label: `${maxYear - i}-${maxYear - i + 1}`,
      max: maxYear - i + 1,
      min: maxYear - i
    }
  })

  if (addBefore2000) {
    filters.push(BEFORE_2000_FILTER)
  }

  return [ALL_YEARS_FILTER, ...filters]
}

function useYearFilter(data, filter) {
  return useMemo(() => {
    if (!filter.yearFilter) {
      return data
    }
    const result = data.filter(update => {
      const year = new Date(update.date).getFullYear()

      return filter.yearFilter.min <= year && filter.yearFilter.max > year
    })
    result.sort(function(a, b) {
      return new Date(b.date) - new Date(a.date)
    })
    return result
  }, [data, filter])
}

const History = ({ title, updates, locale }) => {
  const { translations } = useStaticQuery(query)
  const translation = getContentByLocale(locale, translations.nodes)
  const yearFilters = useMemo(() => makeYearFilters(updates), [updates])
  const [filter, setFilter] = useState({ yearFilter: ALL_YEARS_FILTER })
  const resourceFiltered = useResourcesFilter(updates, filter, locale, {
    searchFields: ['title', 'description']
  })
  const filtered = useYearFilter(resourceFiltered, filter)

  ALL_YEARS_FILTER.label = translation.optionAll

  return (
    <div className="container mt-60 mt-lg-120">
      <div className="row d-flex align-items-center justify-content-between mb-60">
        <div className="col-12 mt-0 mt-lg-32">
          <PageHeader title={title} type="h3" />
        </div>
      </div>

      <div className="row">
        {/* sidebar filter */}
        <div className="col-12 col-lg-2 d-none d-lg-flex flex-wrap flex-row flex-lg-column justify-content-between justify-content-lg-start mt-8">
          {yearFilters.map(yearFilter => {
            return (
              <button
                key={yearFilter.label}
                className={cn(
                  'content-jumplink mb-16 pb-4 border-b-white ta-left',
                  yearFilter === filter.yearFilter && 'content-jumplink-active'
                )}
                style={{ width: '74px' }}
                onClick={() => setFilter({ ...filter, yearFilter })}
              >
                <p>{yearFilter.label}</p>
              </button>
            )
          })}
        </div>

        <div className="col-12 col-lg-10 mt-40 mt-lg-0">
          {filtered.map(update => (
            <AlgorithmHistory key={update.id}>
              <AlgorithmHistory.Header
                date={update.date}
                confirmed={update.confirmed}
                confirmRender={true}
              >
                {update.title}
              </AlgorithmHistory.Header>
              <AlgorithmHistory.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: useForceBlank(update.description)
                  }}
                />
              </AlgorithmHistory.Body>
            </AlgorithmHistory>
          ))}
        </div>
      </div>
    </div>
  )
}

export default History
